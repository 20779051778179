import {Component, OnDestroy} from '@angular/core'
import {Subscription} from 'rxjs'

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
  protected subs: Subscription[] = []

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
