import {Component} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import { HeaderComponent } from './components/header/header.component'
import { RouterOutlet } from '@angular/router'
import { AsyncPipe } from '@angular/common'

@Component({
    selector: 'spb-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
  imports: [HeaderComponent, RouterOutlet, AsyncPipe, FooterComponent]
})
export class AppComponent {
  public version = version.version
  public copyrightYears = `2024-${new Date().getFullYear()}`
  constructor(
    public configService: ConfigService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Add custom SVG icons to use later in <mat-icon>
    this.matIconRegistry.addSvgIcon(
      'signed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/signed.svg')
    )
  }
}
