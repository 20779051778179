import {Component} from '@angular/core'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {ConfigService} from '../../services/config.service'
import { MatButton } from '@angular/material/button'
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu'
import { MatIcon } from '@angular/material/icon'
import { AsyncPipe } from '@angular/common'

@Component({
    selector: 'spb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
  imports: [MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, AsyncPipe, LogoComponent]
})
export class HeaderComponent {
  constructor(public configService: ConfigService) {
  }
}
